<template>
    <div class="login-company">
      <div class="container"  v-if="Companies.length > 0">
        <h2 class="title">
            {{ $t('Select the company to continue') }}
        </h2>
        <p>
            {{ $t('SelectCompany') }}
        </p>
        <div class="companies">
            <div class="item" v-for="item in Companies" :key="item.id">
                <div class="icon">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 30.2516H31M14.5 19.7516H17.5C17.7954 19.7516 18.0878 19.8098 18.3607 19.9228C18.6336 20.0359 18.8816 20.2015 19.0904 20.4104C19.2993 20.6193 19.4649 20.8672 19.578 21.1401C19.691 21.413 19.7492 21.7054 19.7492 22.0008V30.2516H12.2524V22.0008C12.2524 21.4043 12.4894 20.8322 12.9112 20.4104C13.333 19.9886 13.9051 19.7516 14.5016 19.7516M11.5 7.75162H20.5M11.5 13.7516H20.5M10.3746 1.75H21.6254C22.8189 1.75 23.9635 2.22411 24.8074 3.06802C25.6513 3.91193 26.1254 5.05653 26.1254 6.25V30.25H5.87459V6.25C5.87459 5.05653 6.3487 3.91193 7.19261 3.06802C8.03653 2.22411 9.18112 1.75 10.3746 1.75Z" stroke="#0a3041" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="info">
                    <div class="title-co">
                        {{ $t('Company Account') }}
                    </div>
                    <div class="name">
                       {{ item.name	}}
                    </div>
                    <div v-if="item.commercial_register">
                        <span>{{ $t('commercial_registration') }}  : </span>
                        <span>{{ item.commercial_register }}</span>
                    </div>
                </div>
                <div class="link" @click="loginAsCompany(item.id)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.31388 11.6042L0.370067 6.53421C0.29516 6.45739 0.241977 6.37417 0.210516 6.28455C0.179055 6.19493 0.163575 6.09891 0.164074 5.99649C0.164074 5.89406 0.179805 5.79804 0.211265 5.70842C0.242726 5.6188 0.29566 5.53558 0.370067 5.45876L5.31388 0.388791C5.45121 0.247959 5.62299 0.17447 5.82924 0.168324C6.03548 0.162179 6.21325 0.235668 6.36257 0.388791C6.51238 0.529624 6.59053 0.705793 6.59703 0.917297C6.60352 1.1288 6.53161 1.31112 6.3813 1.46424L2.71089 5.22831H11.0817C11.2939 5.22831 11.4719 5.30205 11.6157 5.44954C11.7596 5.59703 11.8312 5.77935 11.8307 5.99649C11.8307 6.21414 11.7591 6.39671 11.6157 6.5442C11.4724 6.69169 11.2944 6.76518 11.0817 6.76467H2.71089L6.3813 10.5287C6.51862 10.6696 6.59053 10.8488 6.59703 11.0665C6.60352 11.2841 6.53161 11.4634 6.3813 11.6042C6.24397 11.7578 6.06919 11.8346 5.85695 11.8346C5.64472 11.8346 5.46369 11.7578 5.31388 11.6042Z" fill="#225476"/>
                    </svg>
                </div>
            </div>
        </div>

      </div>

      <div class='text-center' v-else>
          <h2 class="title">
            {{ $t('No Companies Exist') }}
        </h2>
        <div clas="mt-4" style="margin-top: 45px;">
        <router-link to="/profile/company/add" class="btn-main mt-4">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.73684 14.7368C6.73684 15.0719 6.86992 15.3931 7.10681 15.63C7.3437 15.8669 7.66499 16 8 16C8.33501 16 8.6563 15.8669 8.89319 15.63C9.13008 15.3931 9.26316 15.0719 9.26316 14.7368V9.26316H14.7368C15.0719 9.26316 15.3931 9.13008 15.63 8.89319C15.8669 8.6563 16 8.33501 16 8C16 7.66499 15.8669 7.3437 15.63 7.10681C15.3931 6.86992 15.0719 6.73684 14.7368 6.73684H9.26316V1.26316C9.26316 0.928148 9.13008 0.606858 8.89319 0.36997C8.6563 0.133082 8.33501 0 8 0C7.66499 0 7.3437 0.133082 7.10681 0.36997C6.86992 0.606858 6.73684 0.928148 6.73684 1.26316V6.73684H1.26316C0.928148 6.73684 0.606858 6.86992 0.36997 7.10681C0.133082 7.3437 0 7.66499 0 8C0 8.33501 0.133082 8.6563 0.36997 8.89319C0.606858 9.13008 0.928148 9.26316 1.26316 9.26316H6.73684V14.7368Z"
                  fill="white"
                />
              </svg>
              {{ $t("Add Company") }}
            </router-link>
          </div>
        </div>
      <Spinner />
    </div>
  </template>

  <script>
  import axios from 'axios';
  import { notify } from "@kyvg/vue3-notification";
  import Cookie from 'cookie-universal'
  const cookie = Cookie()
  import { defineAsyncComponent } from 'vue'
  export default {
    data() {
      return {
        Companies:[]
      }
    },
    components: {
      Spinner: defineAsyncComponent(() => import('@/components/Global/Spinner.vue')),
    },
    methods: {

      async loginAsCompany(id) {
        var self = this;
        self.$store.dispatch('SETSpinner', true);
        const formData = {
          "company_id": id,
        }
        await axios.post(`/company/dashboard/auth/login`, formData,{
            headers:{
                "Authorization": `Bearer ${cookie.get('Token')}`
            }
        })
          .then((res) => {
              self.$store.commit("SET_CompanyToken", res.data.data);
            self.$store.dispatch('SETSpinner', false);
            self.$router.push('/profile/home');
          })
          .catch(function (error) {
            if(error){
              self.$store.dispatch('SETSpinner', false);
              notify({
                type: "error",
                title: "خـطـأ !!",
                text: error?.response?.data?.message,
              });
            }
          });
      }
    },
    mounted(){
        this.$store.dispatch('GetCompanies').then(r => {
        this.Companies = this.$store.state.Companies;
    });
    }
  }
  </script>
